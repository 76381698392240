.student-class-box-container {
    margin-top: 15px;
    gap: 15px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: 800px) {
    .student-class-box-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media screen and (max-width: 550px) {
    .student-class-box-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .Card-min-box {
    padding: 30px;
    border: none;
    width: 100%;
    max-width: 260px;
    text-align: center;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  .Card-min-box:hover {
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
    background-color: rgba(238, 236, 236, 0.7);
  }
  @media screen and (max-width: 360px) {
    .Card-min-box {
      padding: 25px 20px;
    }
  }
  