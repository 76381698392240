*::-webkit-scrollbar {
  display: none;
}

.form {
  width: 100%;
  height: auto;
  align-items: center;
  display: flex;
  overflow-y: auto;
  gap: 12px;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 400px;
}
.editFormLayer {
  width: 100%;
  height: 400px;
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: scroll;
}

.modalBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}
.rows {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
}
.formHeaderRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subMenuLinks {
  gap: 5px;
  padding: 0px 10px 0px 10px;
  border-radius: 5px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
.subMenuLinks:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.saveButton {
  width: 100%;
  display: flex;
  justify-content: end;
  padding-right: 20px;
}

.tableBox table,
.studentsTable table,
.overallTable table,
.psychomotorEvaluationTable table,
.enabilityTable table {
  margin-top: 10px;
}
.broadSheetBox {
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  position: relative;
}
.resultBox {
  width: 100%;
  overflow-x: auto;
}
/* .resultLayer {
  min-width: 900px;
} */
.tableBox {
  min-width: 670px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid gray;
}
.tableHeaderRow {
  width: 670px;
  display: grid;
  grid-template-columns: 110px 110px 105px 100px 117.5px 117.5px;
}
.tableBox tr {
  border-top: 1px solid gray;
}
.studentsTable {
  min-width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid gray;
}
.enabilityTable {
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid gray;
}
.studentsTable tr {
  width: 220px;
  display: grid;
  border-top: 1px solid gray;
  grid-template-columns: 50px 170px;
}
.enabilityTable tr {
  width: 100px;
  border-top: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
}
.overallTable {
  min-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid gray;
}
.overallTable tr {
  min-width: 450px;
  display: grid;
  border-top: 1px solid gray;
  grid-template-columns: 150px 150px 150px;
}
.psychomotorEvaluationTable {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.psychomotorEvaluationTable tr {
  min-width: 200px;
  display: grid;
  grid-template-columns: 40px 40px 40px 40px 40px;
  border-top: 1px solid gray;
}
.psychomotorEvaluationTable tr:nth-child(1) {
  display: flex;
  flex-direction: column;
}
.psychomotorEvaluationTable div:nth-child(2) {
  min-width: 200px;
  display: grid;
  grid-template-columns: 40px 40px 40px 40px 40px;
}
.psychomotorEvaluationTable td {
  display: flex;
  justify-content: center;
  height: 25px;
}

.psychomotorHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
}
.psychomotorDropdownBox {
  cursor: pointer;
  width: 200px;
}
.selectedEvaluation {
  width: 100%;
  display: flex;
  justify-content: center;
}
.evaluationMenuBox {
  width: 200px;
  height: auto;
  position: absolute;
  top: 35px;
}
.evaluationMenuBox ul {
  display: flex;
  flex-direction: column;
  list-style: none;
}
.evaluationMenuBox ul li {
  padding: 2px 7px;
  width: 100%;
}
.evaluationMenuBox ul li:hover {
  background-color: rgba(182, 181, 181, 0.5);
}
.tableBox tr,
.studentsTable tr,
.overallTable tr,
.psychomotorEvaluationTable tr,
.enabilityTable tr {
  height: 50px;
  align-items: center;
}
.settingsDropDown {
  display: flex;
  flex-direction: column;
}
.itemList {
  padding: 8px 14px;
  width: 100%;
  /* max-width: 450px; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.itemList:hover {
  background-color: rgba(182, 181, 181, 0.5);
}
.itemList button {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.itemListDropdown {
  position: relative;
}
.itemListMenu {
  position: absolute;
  top: 25px;
  right: 0px;
  width: 180px;
  height: auto;
  display: none;
}
.itemListContent {
  width: 100%;
  padding: 3.5px 12px;
}
.itemListContent:hover {
  background-color: rgba(182, 181, 181, 0.7);
}
.loadingLayer {
  width: 100vw;
  height: 100vh;
  z-index: 5;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
}
.loadingLayer img {
  height: 60px;
}

@media (min-width: 400px) {
  .child-result {
    margin-left: 2px;
  }
}
.student-action-button-container{
  gap: 20px;
  display: flex;
  justify-content: space-between;
}